import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaGoodreads, FaAmazon } from 'react-icons/fa';
import { SiAdobe } from 'react-icons/si';
import ReactMarkdown from 'react-markdown';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

const DEFAULT_BOOK_SUGGESTIONS = [
  "Best science fiction books of 2024",
  "Popular self-improvement books",
  "Award-winning novels",
  "Business strategy books",
  "Classic literature recommendations"
];

const DEFAULT_PAPER_SUGGESTIONS = [
  "Latest AI research papers",
  "Climate change studies",
  "Machine learning applications",
  "Quantum computing advances",
  "Renewable energy research"
];

const MainPage = () => {
  const [latestBooks, setLatestBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBook, setSelectedBook] = useState(null);
  const [showChatbot, setShowChatbot] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [chatWindows, setChatWindows] = useState({});
  const [activeChatId, setActiveChatId] = useState(null);
  const [minimizedChats, setMinimizedChats] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [chatOrder, setChatOrder] = useState([]);
  const [searchType, setSearchType] = useState('books');
  const [parsingStatus, setParsingStatus] = useState({});
  const [isPromptsMinimized, setIsPromptsMinimized] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestedSearches, setSuggestedSearches] = useState([]);
  // const [isGenerating, setIsGenerating] = useState(false);
  const [bookSuggestions, setBookSuggestions] = useState([]);
  const [paperSuggestions, setPaperSuggestions] = useState([]);
  const [lastSearchedQuery, setLastSearchedQuery] = useState('');
  // const [setPodcastStatus] = useState(null);
  const [messageAudioStatus, setMessageAudioStatus] = useState({});
  const [chatHeight, setChatHeight] = useState(600); // Default height
  const [isResizing, setIsResizing] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [isLeftPaneOpen, setIsLeftPaneOpen] = useState(false);
  // const [setSearchHistory] = useState([]);
  const [favorites, setFavorites] = useState([]);

//   const generatePodcast = async () => {
//     if (!activeChatId || isGenerating) return;

//     try {
//       setIsGenerating(true);
      
//       // Format chat messages as a dialogue between Host 1 (user) and Host 2 (assistant)
//       const chatContent = chatWindows[activeChatId].messages
//         .map(msg => {
//           // Clean up the content by removing any existing "Host" prefixes and extra whitespace
//           const cleanContent = msg.content
//             .replace(/^(Host \d:)/gm, '')
//             .trim();
            
//           // Add appropriate host prefix based on message role
//           return `${msg.role === 'user' ? 'Host 1' : 'Host 2'}: ${cleanContent}`;
//         })
//         .join('\n\n');

//       // Add introduction and context
//       const title = chatWindows[activeChatId].title;
//       const type = chatWindows[activeChatId].type;
//       const formattedContent = `
// Host 1: Welcome to our discussion about ${title}! ${type === 'book' ? "Let's explore this interesting book together." : "Let's dive into this research paper."}
// Host 2: I'm excited to share insights and discuss the key points with you.

// ${chatContent}

// Host 1: Thank you for this enlightening discussion!
// Host 2: It was my pleasure. I hope our listeners found this conversation informative and engaging.`.trim();

//       const response = await axios.post(`${API_BASE_URL}/gpt-backend/generate-podcast`, {
//         chatContent: formattedContent,
//         hostName: 'Host 1',
//         guestName: 'Host 2'
//       });

//       startStatusPolling(response.data.statusId);

//     } catch (error) {
//       console.error('Error starting podcast generation:', error);
//       setIsGenerating(false);
//     }
//   };

//   const startStatusPolling = (statusId) => {
//     const pollInterval = setInterval(async () => {
//         try {
//             const response = await axios.get(`${API_BASE_URL}/gpt-backend/podcast-status/${statusId}`);
//             setPodcastStatus(response.data);

//             if (response.data.status === 'completed' || response.data.status === 'error') {
//                 clearInterval(pollInterval);
//                 setIsGenerating(false);
                
//                 if (response.data.status === 'completed') {
//                     // Handle completed podcast (e.g., show download link)
//                     window.open(response.data.url, '_blank');
//                 }
//             }
//         } catch (error) {
//             console.error('Error polling podcast status:', error);
//             clearInterval(pollInterval);
//             setIsGenerating(false);
//         }
//     }, 2000);
//   };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (searchQuery === '') {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [searchQuery]);

  const handleInputClick = () => {
    setShowSuggestions(true);
    // Show appropriate suggestions based on search type
    setSuggestedSearches(searchType === 'books' ? bookSuggestions : paperSuggestions);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    // Only show suggestions if there's a last searched query and the current input is empty
    if (!value.trim()) {
      setShowSuggestions(true);
      if (lastSearchedQuery) {
        // Show suggestions based on last search
        setSuggestedSearches(prevSuggestions => prevSuggestions);
      } else {
        // Show default suggestions if no previous search
        setSuggestedSearches(searchType === 'books' ? DEFAULT_BOOK_SUGGESTIONS : DEFAULT_PAPER_SUGGESTIONS);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
    
    // Use the suggestion value directly instead of relying on searchQuery state
    try {
      setLatestBooks([]);
      setStartIndex(0);
      setHasMore(true);
      setError(null);
      setLastSearchedQuery(suggestion);
      
      if (searchType === 'papers') {
        await fetchResearchPapers(suggestion);
      } else {
        await fetchCategoryAndBooks(suggestion, 0);
      }
      
      // Generate new suggestions based on the successful search
      const suggestions = await generateSearchSuggestions(suggestion, searchType);
      setSuggestedSearches(suggestions);
      
    } catch (error) {
      setError('Failed to fetch results. Please try again.');
    }
  };

  const fetchCategoryAndBooks = async (query, startIndex = 0) => {
    if (!query.trim()) {
      setError('Please enter a valid search query.');
      return;
    }
  
    try {
      setError(null);
      setLoading(true);

      // Add timestamp and random nonce to prevent caching
      const timestamp = Date.now();
      const nonce = Math.random().toString(36).substring(7);

      const response = await axios.get(`${API_BASE_URL}/gpt-backend/fetchBooks`, {
        params: { 
          query,
          startIndex,
          pageSize: 20,
          timestamp,
          nonce // Add random nonce
        },
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });

      // Process the response
      const isbnBooks = response.data.books.map(item => ({
        title: item.title,
        author: Array.isArray(item.authors) ? item.authors.join(', ') : item.authors || 'Unknown',
        abstract: item.synopsis || 'No description available.',
        coverUrl: item.image || '',
        publisher: item.publisher || 'Unknown',
        publishDate: item.publishDate || 'Unknown',
        isbn: item.isbn13 || 'Unknown',
        pages: item.pages || 'N/A',
        language: item.language || 'Unknown',
        categories: item.categories || []
      }));

      // Update hasMore based on response data
      setHasMore(response.data.hasMore || false);

      // Update books state
      setLatestBooks(prevBooks => {
        if (startIndex === 0) {
          return isbnBooks;
        }
        // Remove duplicates when adding more books
        const newBooks = [...prevBooks];
        isbnBooks.forEach(book => {
          if (!newBooks.some(existingBook => existingBook.title === book.title)) {
            newBooks.push(book);
          }
        });
        return newBooks;
      });

      setStartIndex(startIndex + 20);

      if (startIndex === 0 && isbnBooks.length === 0) {
        setError('No results found. Please try a different query.');
      }

    } catch (error) {
      console.error('Error details:', error.response?.data || error.message);
      
      if (error.response?.status === 403) {
        setError('Access denied. Please check API configuration.');
      } else if (error.response?.status === 401) {
        setError('Unauthorized. Please check API configuration.');
      } else if (error.response?.status === 400) {
        setError('Invalid search query. Please try again.');
      } else {
        setError('Error fetching books. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Modify handleSearchTypeChange
  const handleSearchTypeChange = (newType) => {
    setSearchType(newType);
    // Reset search results
    setLatestBooks([]);
    setStartIndex(0);
    setHasMore(true);
    setError(null);
    
    // Reset search query and suggestions
    setSearchQuery('');
    if (!lastSearchedQuery) {
      setSuggestedSearches(newType === 'books' ? DEFAULT_BOOK_SUGGESTIONS : DEFAULT_PAPER_SUGGESTIONS);
    }
  };

  // Modify handleSearch
  const handleSearch = async () => {
    // Get the current search query from state
    const query = searchQuery.trim();
    if (!query) return;
    
    // Reset states before new search
    setLatestBooks([]);
    setStartIndex(0);
    setHasMore(true);
    setError(null);
    setLastSearchedQuery(query);
    
    try {
      if (searchType === 'papers') {
        await fetchResearchPapers(query);
      } else {
        await fetchCategoryAndBooks(query, 0);
      }
      
      // Generate new suggestions based on the successful search
      const suggestions = await generateSearchSuggestions(query, searchType);
      setSuggestedSearches(suggestions);
      setShowSuggestions(true);
      
    } catch (error) {
      setError('Failed to fetch results. Please try again.');
    }
  };

  const handleBookClick = (content, type = 'book') => {
    // If there's an active chat, minimize it first
    if (activeChatId && content.title !== chatWindows[activeChatId]?.title) {
      setMinimizedChats(prev => [...prev, activeChatId]);
    }

    if (selectedBook === content) {
      setSelectedBook(null);
    } else {
      setSelectedBook(content);
      
      const contentId = content.title;
      
      if (!chatWindows[contentId]) {
        // Process book data for chat
        const processedContent = type === 'book' ? {
          ...content,
          author: extractAuthorFromDescription(content)
        } : content;

        // Create system message based on content type
        const systemMessage = {
          role: 'system',
          content: type === 'paper' 
            ? `You are a knowledgeable assistant discussing the research paper "${processedContent.title}" by ${processedContent.authors}. 
Paper Details:
- Title: ${processedContent.title}
- Authors: ${processedContent.authors}
- Published Date: ${processedContent.publishDate || 'Not specified'}
- Categories: ${processedContent.categories?.join(', ') || 'Not specified'}

Abstract: ${processedContent.abstract}

Please provide detailed, accurate responses about this specific paper.`
            : `You are a knowledgeable assistant discussing the book "${processedContent.title}" by ${processedContent.author}. 
Book Details:
- Title: ${processedContent.title}
- Author: ${processedContent.author}
- Publisher: ${processedContent.publisher || 'Not specified'}
- Published Date: ${processedContent.publishDate || 'Not specified'}
- Pages: ${processedContent.pages || 'Not specified'}
- Categories: ${processedContent.categories?.join(', ') || 'Not specified'}

Book Description: ${processedContent.abstract || 'Not available'}

Please provide detailed, accurate responses about this specific book.`
        };

        // Determine if the book is fiction based on its description
        const isFiction = processedContent.abstract?.toLowerCase().includes('novel') || 
                         processedContent.abstract?.toLowerCase().includes('story') ||
                         processedContent.abstract?.toLowerCase().includes('fantasy') ||
                         processedContent.abstract?.toLowerCase().includes('adventure');

        const newChatWindow = {
          messages: [], // Start with empty messages
          systemMessage: systemMessage, // Store system message separately
          followUpPrompts: type === 'paper' 
            ? [
                "What are the main findings of this paper?",
                "Can you explain the methodology used?",
                "What are the key contributions?"
              ] 
            : isFiction 
              ? [
                  "What is this book about?",
                  "Tell me about the main characters",
                  "What are the key themes and conflicts?"
                ]
              : [
                  "What is this book about?",
                  "What are the main arguments presented?",
                  "What solutions or insights does the author provide?"
                ],
          type: type,
          title: processedContent.title,
          id: contentId,
          [type]: processedContent
        };

        setChatWindows(prev => ({
          ...prev,
          [contentId]: newChatWindow
        }));
      }
      setActiveChatId(contentId);
      setShowChatbot(true);
      setUserMessage(''); // Clear input when opening new chat
    }
  };

  const addUserMessage = (chatId, message) => {
    const currentChat = chatWindows[chatId];
    if (!currentChat) return null;

    const updatedChat = {
      ...currentChat,
      messages: [
        ...currentChat.messages,
        { role: 'user', content: message, message: message }
      ]
    };

    setChatWindows(prev => ({
      ...prev,
      [chatId]: updatedChat
    }));

    return updatedChat;
  };

  const addTemporaryBotMessage = (chatId) => {
    const messageId = Date.now();
    
    setChatWindows(prev => ({
      ...prev,
      [chatId]: {
        ...prev[chatId],
        messages: [
          ...prev[chatId].messages,
          { role: 'assistant', content: '', id: messageId }
        ]
      }
    }));

    return messageId;
  };

  const updateBotResponse = (chatId, messageId, data) => {
    setChatWindows(prev => {
      const currentMessages = prev[chatId].messages;
      return {
        ...prev,
        [chatId]: {
          ...prev[chatId],
          messages: currentMessages.map(msg => 
            msg.id === messageId ? { ...data, id: messageId } : msg
          )
        }
      };
    });
  };

  const handleBookChatMessage = async (chatId, message, currentChat, updatedChat) => {
    const messageId = addTemporaryBotMessage(chatId);

    // Debug log for book chat
    console.log('Sending book chat request:', {
      bookData: {
        title: currentChat.book?.title,
        author: currentChat.book?.author,
        abstract: currentChat.book?.abstract?.substring(0, 100) + '...',
      },
      messageCount: updatedChat.messages.length
    });

    // Format messages for Eden AI
    const formattedMessages = updatedChat.messages.map(msg => ({
      role: msg.role,
      message: msg.content || msg.message
    }));

    // Send message to book chat endpoint
    const response = await fetch(
      `${API_BASE_URL}/gpt-backend/book-chat`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          previous_history: formattedMessages,
          book: currentChat.book
        })
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('Book chat request failed:', {
        status: response.status,
        statusText: response.statusText,
        error: errorData
      });
      throw new Error(`Book chat request failed: ${errorData.content || response.statusText}`);
    }

    const data = await response.json();
    console.log('Received book response:', {
      role: data.role,
      contentPreview: data.content?.substring(0, 100) + '...'
    });

    setUserMessage('');

    updateBotResponse(chatId, messageId, {
      role: 'assistant',
      content: data.content || data.message,
      message: data.content || data.message
    });

    // Generate follow-up questions after updating messages
    const followUpResponse = await fetch(`${API_BASE_URL}/gpt-backend/generate-follow-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        conversation: updatedChat.messages
          .filter(msg => msg.role === 'user' || msg.role === 'assistant')
          .map(msg => ({
            role: msg.role,
            content: msg.content || msg.message
          })),
        book: currentChat.book,
        paper: currentChat.paper
      })
    });

    if (followUpResponse.ok) {
      const questions = await followUpResponse.json();
      setChatWindows(prev => ({
        ...prev,
        [chatId]: {
          ...prev[chatId],
          followUpPrompts: Array.isArray(questions) ? questions : prev[chatId].followUpPrompts
        }
      }));
    }
  };

  const handlePaperChatMessage = async (chatId, message, currentChat, updatedChat) => {
    const messageId = addTemporaryBotMessage(chatId);

    // Debug log for paper chat
    console.log('Sending paper chat request:', {
      message: message.trim(),
      documentId: currentChat.paper.documentId,
      conversationLength: updatedChat.messages.length,
      paperInfo: {
        title: currentChat.paper?.title,
        authors: currentChat.paper?.authors
      }
    });

    try {
      // Send message to paper chat endpoint
      const response = await fetch(
        `${API_BASE_URL}/gpt-backend/paper-chat`, 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: message.trim(),
            documentId: currentChat.paper.documentId,
            conversation: updatedChat.messages.map(msg => ({
              role: msg.role,
              content: msg.content || msg.message
            })),
            title: currentChat.paper.title,
            authors: currentChat.paper.authors
          })
        }
      );

      const data = await response.json();
      console.log('Received paper chat response:', data);

      if (!response.ok) {
        console.error('Paper chat request failed:', {
          status: response.status,
          statusText: response.statusText,
          error: data
        });
        throw new Error(data.error || data.details || 'Failed to get response from server');
      }

      if (!data.content) {
        console.error('Invalid response format:', data);
        throw new Error('Invalid response format from server');
      }

      // Update bot response
      updateBotResponse(chatId, messageId, {
        role: 'assistant',
        content: data.content,
        message: data.content
      });

      // Clear input after receiving response
      setUserMessage('');

      // Generate follow-up questions after updating messages
      const followUpResponse = await fetch(`${API_BASE_URL}/gpt-backend/generate-follow-up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversation: [
            currentChat.systemMessage,
            ...updatedChat.messages
          ],
          paper: {
            title: currentChat.paper.title,
            authors: currentChat.paper.authors,
            abstract: currentChat.paper.abstract,
            documentId: currentChat.paper.documentId,
            content: currentChat.paper.content
          }
        })
      });

      if (followUpResponse.ok) {
        const questions = await followUpResponse.json();
        setChatWindows(prev => ({
          ...prev,
          [chatId]: {
            ...prev[chatId],
            followUpPrompts: Array.isArray(questions) ? questions : prev[chatId].followUpPrompts
          }
        }));
      }
    } catch (error) {
      console.error('Chat error:', error);
      
      // Create a user-friendly error message
      const errorMessage = error.message.includes('Failed to fetch')
        ? 'Unable to connect to the server. Please check your internet connection and try again.'
        : `I apologize, but I encountered an error: ${error.message}. Please try again.`;

      updateBotResponse(chatId, messageId, {
        role: 'assistant',
        content: errorMessage,
        isError: true
      });
    }
  };

  const handleSendMessage = async (message, chatId = activeChatId) => {
    if (!message.trim() || !chatId) return;

    const currentChat = chatWindows[chatId];
    if (!currentChat) return;

    try {
      const updatedChat = addUserMessage(chatId, message);
      if (!updatedChat) return;

      // Route to appropriate handler based on content type
      if (currentChat.type === 'book') {
        await handleBookChatMessage(chatId, message, currentChat, updatedChat);
      } else if (currentChat.type === 'paper') {
        await handlePaperChatMessage(chatId, message, currentChat, updatedChat);
      }
    } catch (error) {
      console.error('Chat error:', error);
      // Update UI to show error
      setChatWindows(prev => ({
        ...prev,
        [chatId]: {
          ...prev[chatId],
          messages: [
            ...prev[chatId].messages.slice(0, -1),
            { 
              role: 'assistant', 
              content: `Sorry, I encountered an error: ${error.message}. Please try again.`
            }
          ]
        }
      }));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(userMessage);
    }
  };

  const minimizeChat = (chatId) => {
    setMinimizedChats(prev => [...prev, chatId]);
    setActiveChatId(null);
  };

  const maximizeChat = (chatId) => {
    // If there's an active chat different from the one being maximized,
    // minimize it first
    if (activeChatId && activeChatId !== chatId) {
      setMinimizedChats(prev => [...prev, activeChatId]);
    }

    // Remove the chat being maximized from minimized chats
    setMinimizedChats(prev => prev.filter(id => id !== chatId));
    setActiveChatId(chatId);
    setUnreadMessages(prev => ({ ...prev, [chatId]: false }));
  };

  const closeChat = (chatId) => {
    setChatWindows(prev => {
      const { [chatId]: removed, ...rest } = prev;
      return rest;
    });
    setMinimizedChats(prev => prev.filter(id => id !== chatId));
    if (activeChatId === chatId) {
      setActiveChatId(null);
    }
    setChatOrder(prev => prev.filter(id => id !== chatId));
  };

  const loadMoreBooks = async () => {
    if (!loading && hasMore && searchQuery) {
      try {
        await fetchCategoryAndBooks(searchQuery, startIndex);
      } catch (error) {
        console.error('Error loading more books:', error);
        setError('Failed to load more books. Please try again.');
      }
    }
  };

  const fetchResearchPapers = async (query, retryCount = 0) => {
    if (!query.trim()) {
      setError('Please enter a valid search query.');
      return;
    }

    try {
      setError(null);
      setLoading(true);

      const response = await axios.get(`${API_BASE_URL}/gpt-backend/fetchPapers`, {
        params: { query },
        timeout: 30000, // Increased to 30 seconds
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      });

      if (!response.data || !response.data.papers) {
        throw new Error('Invalid response structure from API');
      }

      const papers = response.data.papers.map(paper => ({
        title: paper.title || 'Unknown Title',
        authors: paper.authors || ['Unknown Author'],
        abstract: paper.abstract || 'No abstract available.',
        link: paper.link || '#',
        publishDate: paper.publishDate || 'Unknown Date'
      }));

      setLatestBooks(papers);
      setHasMore(false);

    } catch (error) {
      console.error('Error fetching papers:', error);
      
      // Retry logic for timeout errors
      if (error.code === 'ECONNABORTED' && retryCount < 2) {
        console.log(`Retry attempt ${retryCount + 1}`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds before retry
        return fetchResearchPapers(query, retryCount + 1);
      }

      // User-friendly error messages
      let errorMessage = 'Error fetching research papers. Please try again later.';
      if (error.code === 'ECONNABORTED') {
        errorMessage = 'The request took too long to complete. Please try again.';
      } else if (error.response?.status === 429) {
        errorMessage = 'Too many requests. Please wait a moment and try again.';
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const initializePaperChat = async (paper) => {
    // If there's an active chat, minimize it first
    if (activeChatId && paper.title !== chatWindows[activeChatId]?.title) {
      setMinimizedChats(prev => [...prev, activeChatId]);
    }

    const chatId = `paper-${Date.now()}`;
    const pdfUrl = paper.link.replace('/abs/', '/pdf/');
    
    try {
      // Early validation
      if (!paper.title || !paper.link) {
        throw new Error('Invalid paper data: Missing title or link');
      }

      console.log('Initializing chat for paper:', paper.title);
      
      // Update parsing status
      setParsingStatus(prev => ({
        ...prev,
        [paper.title]: 'parsing'
      }));

      // Create initial chat window to show loading state
      const initialChatWindow = {
        messages: [], 
        followUpPrompts: [
          "What are the main findings of this paper?",
          "Can you explain the methodology used?",
          "What are the key contributions?"
        ],
        paper: {
          title: paper.title,
          link: paper.link,
          ...paper
        },
        type: 'paper',
        title: paper.title, 
        id: chatId,
        isLoading: true
      };

      // Set initial chat window
      setChatWindows(prev => ({
        ...prev,
        [chatId]: initialChatWindow
      }));

      // Parse PDF content
      const response = await axios.post(
        `${API_BASE_URL}/gpt-backend/parse-pdf`,
        { 
          pdfUrl,
          paperTitle: paper.title 
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 30000
        }
      );

      const { documentId } = response.data;

      // Validate parsed content
      if (!documentId) {
        throw new Error('PDF parsing returned empty content');
      }

      console.log(`PDF parsed successfully: ${documentId}`);

      // Update chat window with parsed content
      const updatedChatWindow = {
        ...initialChatWindow,
        paper: {
          ...initialChatWindow.paper,
          documentId,
          abstract: paper.abstract || '',
          authors: paper.authors || [],
          year: paper.year || '',
        },
        isLoading: false,
        lastUpdated: new Date().toISOString()
      };

      // Update chat windows with complete data
      setChatWindows(prev => ({
        ...prev,
        [chatId]: updatedChatWindow
      }));

      // Update chat order and activate window
      setChatOrder(prev => [...prev, chatId]);
      setActiveChatId(chatId);
      setShowChatbot(true);
      // Reset chat height to default when opening new chat
      // setChatHeight(700);

      // Update parsing status to complete
      setParsingStatus(prev => ({
        ...prev,
        [paper.title]: 'complete'
      }));

      return chatId; 

    } catch (error) {
      console.error('Error in initializePaperChat:', {
        paperTitle: paper.title,
        error: error.message,
        stack: error.stack
      });

      // Update chat window to show error state
      setChatWindows(prev => ({
        ...prev,
        [chatId]: {
          ...prev[chatId],
          isLoading: false,
          error: error.message
        }
      }));

      // Update parsing status to error
      setParsingStatus(prev => ({
        ...prev,
        [paper.title]: 'error'
      }));

      // Show error message to user
      setError(`Failed to load paper: ${error.message}`);

      return null;
    }
  };

  const renderGridItem = (item, index) => {
    if (searchType === 'papers') {
      const {
        title = 'Untitled',
        authors = [],
        abstract = 'No abstract available',
        link = '',
        pdfLink = '',
        publishDate = 'Unknown Date',
        category = '',
        categories = []
      } = item;

      const authorList = Array.isArray(authors) ? authors : [authors].filter(Boolean);
      const status = parsingStatus[title];

      return (
        <div key={index} className="paper-item">
          <div 
            className="paper-cover"
            onClick={() => initializePaperChat(item)}
          >
            <h3>{title}</h3>
            <p className="publish-date">{publishDate}</p>
            <p className="authors">{authorList.join(', ') || 'Unknown Author(s)'}</p>
            <p className="categories">
              {category && <span className="primary-category">{category}</span>}
              {categories.filter(cat => cat !== category).map((cat, idx) => (
                <span key={idx} className="secondary-category">{cat}</span>
              ))}
            </p>
            <p className="abstract">{abstract}</p>
            <div className="paper-links">
              <a href={link} target="_blank" rel="noopener noreferrer" className="paper-link">
                View on arXiv
              </a>
              {pdfLink && (
                <a href={pdfLink} target="_blank" rel="noopener noreferrer" className="pdf-link">
                  Download PDF
                </a>
              )}
            </div>
            <div className="paper-footer">
              {status === 'parsing' ? (
                <span className="parsing-status">
                  <div className="spinner"></div>
                  Analyzing paper...
                </span>
              ) : status === 'error' ? (
                <span className="error-status">
                  Error analyzing paper. Click to try again.
                </span>
              ) : (
                <span className="chat-prompt">
                  Click to chat about this paper
                </span>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div key={index} className="book-item" onClick={() => handleBookClick(item)}>
          {selectedBook === item ? (
            <div className="abstract-container" style={{ display: selectedBook === item ? 'block' : 'none' }}>
              <h3>{item.title}</h3>
              <p>{item.abstract}</p>
            </div>
          ) : (
            <img src={item.coverUrl || './no-image.png'} alt={item.title} />
          )}
          <div className="icon-links">
            <a href={`https://www.goodreads.com/search?q=${encodeURIComponent(item.title)}`} target="_blank" rel="noopener noreferrer">
              <FaGoodreads />
            </a>
            <a href={`https://www.amazon.com/s?k=${encodeURIComponent(item.title)}`} target="_blank" rel="noopener noreferrer">
              <FaAmazon />
            </a>
            <a href={`https://annas-archive.org/search?q=${encodeURIComponent(item.title)}`} target="_blank" rel="noopener noreferrer">
              <SiAdobe />
            </a>
          </div>
        </div>
      );
    }
  };

  // Helper function to extract author from book description
  const extractAuthorFromDescription = (book) => {
    if (book.author && book.author !== 'Unknown Author') {
      return book.author;
    }
    
    // Try to extract from abstract
    if (book.abstract) {
      const authorMatch = book.abstract.match(/by\s+([^<.,\n]+)/);
      if (authorMatch && authorMatch[1]) {
        return authorMatch[1].trim();
      }
    }
    return 'Unknown Author';
  };

  const generateMessageAudio = async (messageId, text, retryCount = 0) => {
    const MAX_RETRIES = 2;
    const RETRY_DELAY = 2000; // 2 seconds

    try {
      setMessageAudioStatus(prev => ({
        ...prev,
        [messageId]: { status: 'generating', progress: 0 }
      }));

      // Split long text into chunks if needed (to prevent timeout)
      const maxChunkLength = 1000;
      if (text.length > maxChunkLength) {
        text = text.substring(0, maxChunkLength);
        console.log('Text truncated to prevent timeout');
      }

      const response = await axios.post(
        `${API_BASE_URL}/gpt-backend/text-to-speech`,
        { text },
        {
          timeout: 30000, // 30 second timeout
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response.data.audioUrl) {
        throw new Error('No audio URL received from server');
      }

      // Pre-load the audio to verify it's valid
      const audio = new Audio();
      
      try {
        await new Promise((resolve, reject) => {
          audio.onerror = () => reject(new Error('Failed to load audio file'));
          audio.oncanplaythrough = resolve;
          
          // Set source after adding event listeners
          audio.src = response.data.audioUrl;
          
          // Add timeout for loading
          setTimeout(() => reject(new Error('Audio loading timeout')), 10000);
        });
      } catch (loadError) {
        throw new Error(`Audio loading failed: ${loadError.message}`);
      }

      // Add error handling for audio loading
      audio.onerror = (e) => {
        console.error('Audio playback error:', e);
        setMessageAudioStatus(prev => ({
          ...prev,
          [messageId]: { 
            status: 'error', 
            error: 'Audio playback failed' 
          }
        }));
      };

      // Add ended event listener
      audio.addEventListener('ended', () => {
        setMessageAudioStatus(prev => ({
          ...prev,
          [messageId]: { 
            ...prev[messageId],
            isPlaying: false 
          }
        }));
      });

      setMessageAudioStatus(prev => ({
        ...prev,
        [messageId]: { 
          status: 'completed',
          audioUrl: response.data.audioUrl,
          audio,
          isPlaying: false
        }
      }));

    } catch (error) {
      console.error('Error generating audio:', error);

      // Determine if we should retry
      const shouldRetry = retryCount < MAX_RETRIES && (
        error.code === 'ERR_BAD_RESPONSE' ||
        error.code === 'ECONNABORTED' ||
        error.message.includes('timeout') ||
        error.message.includes('socket hang up') ||
        error.response?.status === 500
      );

      if (shouldRetry) {
        console.log(`Retrying audio generation (attempt ${retryCount + 1})`);
        setMessageAudioStatus(prev => ({
          ...prev,
          [messageId]: { 
            status: 'retrying', 
            attempt: retryCount + 1 
          }
        }));

        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return generateMessageAudio(messageId, text, retryCount + 1);
      }

      // If we're out of retries or shouldn't retry, show error
      setMessageAudioStatus(prev => ({
        ...prev,
        [messageId]: { 
          status: 'error', 
          error: error.response?.data?.details || error.message,
          retryCount
        }
      }));
    }
  };

  const handleAudioPlayback = async (messageId, audioStatus) => {
    try {
      if (!audioStatus.audio) return;

      // Stop any other playing audio first
      Object.entries(messageAudioStatus).forEach(([id, status]) => {
        if (status.audio && status.isPlaying && id !== messageId) {
          status.audio.pause();
          setMessageAudioStatus(prev => ({
            ...prev,
            [id]: { ...prev[id], isPlaying: false }
          }));
        }
      });

      if (audioStatus.isPlaying) {
        audioStatus.audio.pause();
        setMessageAudioStatus(prev => ({
          ...prev,
          [messageId]: { 
            ...prev[messageId],
            isPlaying: false
          }
        }));
      } else {
        try {
          await audioStatus.audio.play();
          setMessageAudioStatus(prev => ({
            ...prev,
            [messageId]: { 
              ...prev[messageId],
              isPlaying: true
            }
          }));
        } catch (error) {
          console.error('Playback failed:', error);
          if (error.name === 'NotAllowedError') {
            alert('Please tap again to play audio');
          }
        }
      }
    } catch (error) {
      console.error('Audio control error:', error);
    }
  };


  const renderChatMessage = (message, index) => {
    const messageId = `message-${message.id || index}`;
    const audioStatus = messageAudioStatus[messageId];
    
    const getAudioIcon = (audioStatus) => {
      if (audioStatus?.status === 'generating') {
        return (
          <span className="generating-audio">
            🔊 {audioStatus.progress ? `${audioStatus.progress}%` : '...'}
          </span>
        );
      } else if (audioStatus?.status === 'retrying') {
        return (
          <span className="retrying-audio">
            🔄 Retry {audioStatus.attempt}/2
          </span>
        );
      } else if (audioStatus?.status === 'loading') {
        return (
          <span className="loading-audio">
            🔊 {audioStatus.loadingProgress}%
          </span>
        );
      } else if (audioStatus?.status === 'error') {
        return (
          <span 
            className="audio-error" 
            title={`Error: ${audioStatus.error}${audioStatus.retryCount ? ` (after ${audioStatus.retryCount} retries)` : ''}`}
          >
            🔇
          </span>
        );
      } else if (audioStatus?.isPlaying) {
        return <span className="audio-playing">⏸️</span>;
      } else {
        return <span className="audio-ready">▶️</span>;
      }
    };

    return (
      <div key={messageId} className={`chat-message ${message.role}`}>
        {message.role === 'assistant' ? (
          <div className="assistant-message">
            <ReactMarkdown>{message.content || ''}</ReactMarkdown>
            <button 
              className={`audio-button ${audioStatus?.status || ''} ${audioStatus?.isPlaying ? 'playing' : ''}`}
              onClick={() => {
                if (!audioStatus) {
                  generateMessageAudio(messageId, message.content);
                } else if (audioStatus.status === 'completed') {
                  handleAudioPlayback(messageId, audioStatus);
                }
              }}
              disabled={audioStatus?.status === 'generating' || audioStatus?.status === 'loading'}
              aria-label={
                !audioStatus ? 'Generate audio' :
                audioStatus.status === 'generating' ? 'Generating audio...' :
                audioStatus.status === 'loading' ? 'Loading audio...' :
                audioStatus.isPlaying ? 'Pause' : 'Play'
              }
            >
              {getAudioIcon(audioStatus)}
            </button>
          </div>
        ) : (
          <div className="user-message">
            {message.content || ''}
          </div>
        )}
      </div>
    );
  };

  const toggleSuggestions = () => {
    setShowSuggestions(prev => !prev);
  };

  useEffect(() => {
    if (searchQuery === '') {
        setShowSuggestions(true);
        if (lastSearchedQuery) {
          // Keep existing suggestions if there was a previous search
          setSuggestedSearches(prevSuggestions => prevSuggestions);
        } else {
          // Show default suggestions if no previous search
          setSuggestedSearches(searchType === 'books' ? DEFAULT_BOOK_SUGGESTIONS : DEFAULT_PAPER_SUGGESTIONS);
        }
    }
  }, [searchQuery, searchType, lastSearchedQuery]);

  useEffect(() => {
    const fetchInitialSuggestions = async () => {
        try {
            const bookResponse = await axios.post(`${API_BASE_URL}/gpt-backend/suggested-searches`, { 
                query: '',
                searchType: 'books'
            });
            setBookSuggestions(bookResponse.data.suggestions);

            const paperResponse = await axios.post(`${API_BASE_URL}/gpt-backend/suggested-searches`, { 
                query: '',
                searchType: 'papers'
            });
            setPaperSuggestions(paperResponse.data.suggestions);

            // Set initial suggestions based on current search type
            setSuggestedSearches(searchType === 'books' ? 
                bookResponse.data.suggestions : 
                paperResponse.data.suggestions
            );
        } catch (error) {
            console.error('Error fetching initial suggestions:', error);
        }
    };

    fetchInitialSuggestions();
  }, [searchType]);

  // Add this function to generate suggestions using GPT
  const generateSearchSuggestions = async (query, type) => {
    try {
      const response = await fetch(`${API_BASE_URL}/gpt-backend/suggested-searches`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          searchType: type
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch suggestions');
      }

      const data = await response.json();
      return data.suggestions;
    } catch (error) {
      console.error('Error generating suggestions:', error);
      return type === 'books' ? DEFAULT_BOOK_SUGGESTIONS : DEFAULT_PAPER_SUGGESTIONS;
    }
  };

  // Add these handlers for resize functionality
  const startResize = (e) => {
    e.preventDefault();
    setIsResizing(true);
    document.addEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', stopResize);
  };

  const handleResize = useCallback((e) => {
    if (isResizing) {
      // Get chat container's position
      const chatContainer = document.querySelector('.chatbot');
      if (chatContainer) {
        const containerRect = chatContainer.getBoundingClientRect();
        const newHeight = containerRect.bottom - e.clientY;
        
        // Set minimum and maximum height
        const clampedHeight = Math.min(Math.max(newHeight, 200), window.innerHeight - 100);
        setChatHeight(clampedHeight);
      }
    }
  }, [isResizing]);

  const stopResize = useCallback(() => {
    setIsResizing(false);
    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', stopResize);
  }, [handleResize]);

  // Clean up event listeners
  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', stopResize);
    };
  }, [handleResize, stopResize]);

  // Add this useEffect for keyboard shortcuts
  useEffect(() => {
    const handleKeyboardShortcut = (e) => {
      // Check for Cmd+H (Mac) or Ctrl+H (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'h') {
        e.preventDefault(); // Prevent default browser behavior
        toggleSuggestions();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyboardShortcut);

    // Cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
    };
  }, []);

  // Add keyboard shortcut handler
  useEffect(() => {
    const handleKeyboardShortcut = (e) => {
      // Check for Cmd+M (Mac) or Ctrl+M (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'm') {
        e.preventDefault(); // Prevent default browser behavior
        
        // Only minimize if there's an active chat
        if (activeChatId && !minimizedChats.includes(activeChatId)) {
          minimizeChat(activeChatId);
        }
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyboardShortcut);

    // Cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
    };
  }, [activeChatId, minimizedChats]); // Add dependencies

  // Add to existing keyboard shortcuts useEffect or create a new one
  useEffect(() => {
    const handleKeyboardShortcut = (e) => {
      // Check for Cmd+F (Mac) or Ctrl+F (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'f') {
        e.preventDefault(); // Prevent default browser search
        if (activeChatId) {
          setIsPromptsMinimized(prev => !prev);
        }
      }
    };

    document.addEventListener('keydown', handleKeyboardShortcut);

    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
    };
  }, [activeChatId]);

  // Add modal component
  const SubscribeModal = () => (
    <div className="modal-overlay" onClick={() => setShowSubscribeModal(false)}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button 
          className="modal-close" 
          onClick={() => setShowSubscribeModal(false)}
        >
          ×
        </button>
        
        <h2 style={{ textAlign: 'center', marginTop: 0 }}>Premium Subscription</h2>
        
        <div className="subscription-plan">
          <div className="plan-price">$3.50</div>
          <div className="plan-period">per month</div>
          
          <ul style={{ textAlign: 'left', color: '#e0e0e0', margin: '20px 0' }}>
            <li>Unlimited searches</li>
            <li>Unlimited AI chat features</li>
            <li>Priority support</li>
            <li>Early access to new features</li>
          </ul>
        </div>

        <button className="subscribe-now-button">
          Subscribe Now
        </button>
      </div>
    </div>
  );

  // Add this function to toggle left pane
  // const toggleLeftPane = () => {
  //   setIsLeftPaneOpen(prev => !prev);
  // };

  // Add function to handle history item click
  // const handleHistoryItemClick = (historyItem) => {
  //   setSearchQuery(historyItem.query);
  //   setSearchType(historyItem.type);
  //   handleSearch();
  //   setIsLeftPaneOpen(false);
  // };

  // // Add function to clear history
  // const clearHistory = () => {
  //   setSearchHistory([]);
  // };

  // Wrap toggleFavorite in useCallback
  const toggleFavorite = useCallback((identifier) => {
    console.log('toggleFavorite called with:', identifier);
    
    // Check if the identifier is a chatId or title
    let chat;
    if (chatWindows[identifier]) {
      // If it's a chatId
      chat = chatWindows[identifier];
    } else {
      // If it's a title, find the chat by title
      const chatId = Object.keys(chatWindows).find(id => chatWindows[id].title === identifier);
      chat = chatId ? chatWindows[chatId] : null;
    }

    // If no chat is found, handle direct removal from favorites
    if (!chat) {
      setFavorites(prev => prev.filter(item => item.title !== identifier));
      return;
    }

    setFavorites(prev => {
      const existingIndex = prev.findIndex(item => item.title === chat.title);
      
      if (existingIndex >= 0) {
        return prev.filter((_, index) => index !== existingIndex);
      } else {
        const content = chat.paper || chat.book;
        
        if (!content) {
          console.error('No content found for chat:', chat);
          return prev;
        }

        const newFavorite = {
          title: chat.title,
          author: chat.type === 'paper' 
            ? (Array.isArray(content.authors) ? content.authors.join(', ') : content.authors)
            : (content.author || 'Unknown'),
          type: chat.type,
          coverUrl: content.coverUrl || content.image,
          abstract: content.abstract || content.description,
          timestamp: new Date().toISOString(),
          publishDate: content.publishDate,
          documentId: content.documentId,
          categories: content.categories || [],
          ...(chat.type === 'paper' && {
            doi: content.doi,
            url: content.url || content.link,
            venue: content.venue
          })
        };

        console.log('Adding new favorite:', newFavorite);
        return [newFavorite, ...prev];
      }
    });
  }, [chatWindows]); // Add chatWindows as dependency

  // Add function to check if item is favorited
  const isFavorited = (title) => {
    return favorites.some(item => item.title === title);
  };

  // Modify the chatbot header to include favorite button
  const renderChatHeader = (chatId) => {
    const chat = chatWindows[chatId];
    
    return (
      <div className="chatbot-header">
        <div className="chat-title">
          {chat.title}
        </div>
        <div className="window-controls">
          <button
            className="favorite-button"
            onClick={() => toggleFavorite(chatId)}
            title={`${isFavorited(chat.title) ? "Remove from" : "Add to"} favorites (⌘/Ctrl + K)`}
          >
            {isFavorited(chat.title) ? "⭐" : "☆"}
            <span className="shortcut-hint">⌘/Ctrl + K</span>
          </button>
          <button 
            onClick={() => minimizeChat(chatId)} 
            title="Minimize (⌘/Ctrl + M)"
          >
            _
            <span className="shortcut-hint">⌘/Ctrl + M</span>
          </button>
          <button 
            onClick={() => closeChat(chatId)} 
            title="Close (⌘/Ctrl + W)"
          >
            ×
            <span className="shortcut-hint">⌘/Ctrl + W</span>
          </button>
        </div>
      </div>
    );
  };

  // Modify the favorite item click handler
  const handleFavoriteClick = (favorite) => {
    // If there's an active chat, minimize it first
    if (activeChatId && favorite.title !== chatWindows[activeChatId]?.title) {
      setMinimizedChats(prev => [...prev, activeChatId]);
    }

    // Check if chat window already exists
    const existingChatId = Object.keys(chatWindows).find(
      id => chatWindows[id].title === favorite.title
    );

    if (existingChatId) {
      // If chat exists, maximize it
      setActiveChatId(existingChatId);
      setMinimizedChats(prev => prev.filter(id => id !== existingChatId));
      setShowChatbot(true);
    } else {
      // Create new chat window
      const contentId = favorite.title;
      
      // Create system message based on content type
      const systemMessage = {
        role: 'system',
        content: favorite.type === 'paper' 
          ? `You are a knowledgeable assistant discussing the research paper "${favorite.title}" by ${favorite.author}. 
Paper Details:
- Title: ${favorite.title}
- Authors: ${favorite.author}
- Published Date: ${favorite.publishDate || 'Not specified'}

Abstract: ${favorite.abstract}

Please provide detailed, accurate responses about this specific paper.`
          : `You are a knowledgeable assistant discussing the book "${favorite.title}" by ${favorite.author}. 
Book Details:
- Title: ${favorite.title}
- Author: ${favorite.author}
- Publisher: ${favorite.publisher || 'Not specified'}
- Published Date: ${favorite.publishDate || 'Not specified'}

Book Description: ${favorite.abstract || 'Not available'}

Please provide detailed, accurate responses about this specific book.`
      };

      // Determine if the book is fiction based on its description
      const isFiction = favorite.abstract?.toLowerCase().includes('novel') || 
                       favorite.abstract?.toLowerCase().includes('story') ||
                       favorite.abstract?.toLowerCase().includes('fantasy') ||
                       favorite.abstract?.toLowerCase().includes('adventure');

      const newChatWindow = {
        messages: [],
        systemMessage: systemMessage,
        followUpPrompts: favorite.type === 'paper' 
          ? [
              "What are the main findings of this paper?",
              "Can you explain the methodology used?",
              "What are the key contributions?"
            ] 
          : isFiction 
            ? [
                "What is this book about?",
                "Tell me about the main characters",
                "What are the key themes and conflicts?"
              ]
            : [
                "What is this book about?",
                "What are the main arguments presented?",
                "What solutions or insights does the author provide?"
              ],
        type: favorite.type,
        title: favorite.title,
        id: contentId,
        [favorite.type]: favorite
      };

      setChatWindows(prev => ({
        ...prev,
        [contentId]: newChatWindow
      }));
      setActiveChatId(contentId);
      setShowChatbot(true);
    }
    
    // Optionally close the favorites pane after selection on mobile
    if (window.innerWidth <= 768) {
      setIsLeftPaneOpen(false);
    }
  };

  // Add this useEffect for the Cmd+K shortcut
  useEffect(() => {
    const handleKeyboardShortcut = (e) => {
      // Check for Cmd+K (Mac) or Ctrl+K (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault(); // Prevent default browser behavior
        
        // Only toggle favorite if there's an active chat
        if (activeChatId) {
          const chat = chatWindows[activeChatId];
          if (chat) {
            toggleFavorite(activeChatId);
          }
        }
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyboardShortcut);

    // Cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
    };
  }, [activeChatId, chatWindows, toggleFavorite]); // Add dependencies

  return (
    <div className={`App ${showChatbot ? 'chatbot-visible' : ''}`}>
      {/* Update Left Pane Toggle Button */}
      <button 
        className={`left-pane-toggle ${isLeftPaneOpen ? 'open' : ''}`}
        onClick={() => setIsLeftPaneOpen(prev => !prev)}
        aria-label="Toggle favorites"
        title="Toggle favorites"
      >
        {isLeftPaneOpen ? '◀' : '▶'}
      </button>

      {/* Left Pane */}
      <div className={`left-pane ${isLeftPaneOpen ? 'open' : ''}`}>
        <h2 className="favorites-title">
          <span className="favorite-icon">⭐</span> Favorites
        </h2>
        <div className="favorites-list">
          {favorites.length === 0 ? (
            <div className="no-favorites">
              No favorites yet. Click the star icon in chat to add favorites.
            </div>
          ) : (
            favorites.map((item, index) => (
              <div key={index} className="favorite-item">
                <div 
                  className="favorite-content"
                  onClick={() => handleFavoriteClick(item)}
                  style={{ cursor: 'pointer' }}
                >
                  {item.coverUrl ? (
                    <img 
                      src={item.coverUrl} 
                      alt={item.title} 
                      className="favorite-cover"
                    />
                  ) : (
                    <div className="favorite-type-icon">
                      {item.type === 'book' ? '📚' : '📄'}
                    </div>
                  )}
                  <div className="favorite-details">
                    <h3>{item.title}</h3>
                    <p className="favorite-author">
                      {Array.isArray(item.author) ? item.author.join(', ') : item.author}
                    </p>
                    <p className="favorite-timestamp">
                      Added {new Date(item.timestamp).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <button 
                  className="remove-favorite"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the favorite click
                    toggleFavorite(item.title);
                  }}
                  title="Remove from favorites"
                >
                  ×
                </button>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="left-panel">
        <h1 className="search-title"> 📄 readwithGPT</h1>
        <div className="search-bar">
          <input 
            type="text" 
            placeholder={`Search for ${searchType === 'books' ? 'books' : 'research papers'}...`}
            value={searchQuery} 
            onChange={handleInputChange}
            onClick={handleInputClick}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <button className="search-bar button" onClick={handleSearch}>Search</button>
          <select 
            className="filter-select" 
            value={searchType} 
            onChange={(e) => handleSearchTypeChange(e.target.value)}
          >
            <option value="books">Books</option>
            <option value="papers">Research Papers</option>
          </select>
        </div>
        
        {showSuggestions && (
          <div className="search-suggestions-container">
            <h3 className="search-next-heading">Search Next</h3>
            <div className="suggested-searches">
              {suggestedSearches.map((search, index) => (
                <div 
                  key={index} 
                  className="suggested-search"
                  onClick={() => handleSuggestionClick(search)}
                >
                  {search}
                </div>
              ))}
            </div>
          </div>
        )}
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="book-grid-container">
          <div className="book-grid">
            {latestBooks.map((item, index) => renderGridItem(item, index))}
          </div>
          {loading && (
            <div className="loading-spinner">
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </div>

      <button 
        className="toggle-suggestions-button" 
        onClick={toggleSuggestions}
        title={`${showSuggestions ? 'Hide' : 'Show'} Suggestions (${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+H)`}
      >
        {showSuggestions ? 'Hide' : 'Show'} Suggestions
        <span className="shortcut-hint">
          {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+H
        </span>
      </button>

      {searchType === 'books' && hasMore && !loading && latestBooks.length > 0 && (
        <div className="load-more-container">
          <button 
            className="load-more-button"
            onClick={loadMoreBooks}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      )}
      

      <button 
        className="chatbot-button"
        onClick={() => setShowChatbot(!showChatbot)}
        title={`${showChatbot ? 'Hide' : 'Show'} Chat`}
      >
        {showChatbot ? '💬' : '🤖'}
        
      </button>

      <div className="chatbot-container" style={{ display: showChatbot ? 'flex' : 'none' }}>
        {/* Minimized chat tabs */}
        <div className="minimized-chats">
          {minimizedChats
            .sort((a, b) => chatOrder.indexOf(a) - chatOrder.indexOf(b))
            .map(chatId => {
              const chatWindow = chatWindows[chatId];
              // Fix: Check if any message in this chat has playing audio
              const isAudioPlaying = Object.entries(messageAudioStatus).some(([messageId, status]) => {
                // Extract the message ID from the audio status key
                const messageIdNumber = messageId.split('-')[1];
                // Check if this message belongs to the current chat window
                const messageInChat = chatWindow.messages.some(msg => 
                  msg.id === messageIdNumber || // Check direct ID match
                  messageId === `message-${msg.id}` || // Check formatted ID match
                  messageId === `message-${chatWindow.messages.indexOf(msg)}` // Check index-based ID
                );
                return status.isPlaying && messageInChat;
              });

              return (
                <button 
                  key={chatId} 
                  className="minimized-chat-tab"
                  onClick={() => maximizeChat(chatId)}
                >
                  <div className="minimized-chat-content">
                    <span className={`chat-icon ${isAudioPlaying ? 'playing' : ''}`}>
                      {isAudioPlaying ? '🔊' : chatWindow?.type === 'paper' ? '📄' : '📚'}
                    </span>
                    <span className="chat-title">{chatWindow?.title || 'Chat'}</span>
                    {unreadMessages[chatId] && (
                      <span className="notification-dot" aria-label="New message"></span>
                    )}
                  </div>
                </button>
              );
            })}
        </div>

        {/* Active chat window */}
        {activeChatId && 
         chatWindows[activeChatId] && 
         !minimizedChats.includes(activeChatId) && (
          <div 
            className="chatbot"
            style={{ height: `${chatHeight}px` }}
          >
            <div className="resize-handle" onMouseDown={startResize}></div>
            {renderChatHeader(activeChatId)}

            <div className="chat-messages">
              {chatWindows[activeChatId].messages.map((msg, index) => renderChatMessage(msg, index))}
            </div>

            {chatWindows[activeChatId]?.followUpPrompts?.length > 0 && (
              <div className={`follow-up-prompts ${isPromptsMinimized ? 'minimized' : ''}`}>
                <div className="follow-up-prompts-header">
                  <span>Follow-up Questions (⌘/Ctrl + F)</span>
                  <span className="shortcut-hint">
                    {navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'} + F to show/hide
                  </span>
                </div>
                <div className="follow-up-prompts-list">
                  {chatWindows[activeChatId].followUpPrompts.map((prompt, index) => (
                    <button 
                      key={index} 
                      className="follow-up-prompt"
                      onClick={() => handleSendMessage(prompt)}
                      title={`Press ${index + 1} to select`}
                    >
                      <span className="prompt-number">{index + 1}</span>
                      {prompt}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="chat-input">
              <input
                type="text"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                className="chat-input"
              />
              <button 
                onClick={() => handleSendMessage(userMessage)}
                disabled={!userMessage.trim()}
                className="send-button"
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Add the mobile toggle button */}
      <button 
        className="mobile-toggle-suggestions" 
        onClick={toggleSuggestions}
        aria-label={showSuggestions ? 'Hide Suggestions' : 'Show Suggestions'}
      >
        {showSuggestions ? '👁️' : '👁️‍🗨️'}
      </button>

      {/* Add subscribe button */}
      <button 
        className="subscribe-button"
        onClick={() => setShowSubscribeModal(true)}
      >
        Subscribe
      </button>

      {/* Add modal */}
      {showSubscribeModal && <SubscribeModal />}
    </div>
  );
};

export default MainPage;